<template>
  <Layout>
    <!-- Start Error-Area Area  -->
    <div class="error-area ptb--200 ptb_sm--60 ptb_md--80">
      <div class="container">
        <div class="row align-item-center">
          <div class="col-lg-12">
            <div class="error-inner">
              <h1>404</h1>
              <h2 class="title">
                Aw snap!
              </h2>
              <p class="description">
                Not sure what you're looking for here.
              </p>
              <div class="view-more-button">
                <a
                  class="btn-default"
                  href="/"
                >Go Back Home</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Error-Area Area  -->
  </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'

    export default {
        name: '404',
        components: {Layout}
    }
</script>
