<template>
  <div>
    <!-- Start Footer Area  -->
    <footer
      v-if="data.showFooter"
      class="rn-footer footer-style-default variation-two"
    >
      <div
        v-if="data.showCta"
        class="rn-callto-action clltoaction-style-default style-7"
      >
        <div class="container">
          <div class="row row--0 align-items-center content-wrapper">
            <div class="col-lg-8 col-md-8">
              <div
                class="inner"
                data-aos="fade-up"
              >
                <div class="content text-left">
                  <Logo :showName="long" />
                  <p class="subtitle">
                    If you are facing challenges in your organization due to operational growth, increasing complexity, or inertia against change, let's talk!
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div
                class="call-to-btn text-start mt_sm--20 text-lg-end"
                data-aos="fade-up"
              >
                <Button
                  title="Book an intro call!"
                  icon="arrow-right"
                  url="https://tidycal.com/greenhollow/15min"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- End Footer Area  -->

    <Copyright v-if="data.showCopyright" :showSocialLinks="true" />

    <ScrollTop />
  </div>
</template>

<script>
    import Copyright from './Copyright'
    import Button from '../../elements/button/Button'
    import ScrollTop from './ScrollTop'
    import Logo from '../../elements/logo/Logo'

    export default {
        name: 'Footer',
        components: {Logo, ScrollTop, Button, Copyright},
        props: {
            data: {
                default: null
            }
        }
    }
</script>