<template>
      <div
        :class="`service ${serviceStyle} ${checkTextAlign}`"
        data-aos="fade-up"
        data-aos-delay="70"
      >
        <div class="icon">
          <Icon
            :name="service.icon"
            :class="`size-${iconSize}`"
          />
        </div>
        <div class="content">
          <h4 class="title w-600">
            <router-link
              to="/service-details"
              v-html="service.title"
            />
          </h4>
          <p
            class="description b1 color-gray mb--0"
            v-html="service.description"
          />
        </div>
      </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    export default {
        name: 'ServiceCard',
        components: {Icon},
        props: {
            iconSize: {
                type: [String, Number],
                default: 62
            },
            service: {},
            serviceStyle: {
                type: String
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>
