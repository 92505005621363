<template>
  <div class="logo">
    <router-link :to="url">
      <img
        class="logo-light"
        :src="lightLogo"
        alt="Corporate Logo"
      >
      <img
        class="logo-dark"
        :src="darkLogo"
        alt="Corporate Logo"
      >
      <span v-if="showName === 'long'">Green Hollow Technology LLC</span>
      <span v-else-if="showName === 'short'">Green Hollow</span>
      <span v-else-if="showName">{{showName}}</span>
    </router-link>
  </div>
</template>

<script>
    export default {
        name: 'Logo',
        props: {
            url: {
                type: String,
                default: '/'
            },
            lightLogo: {
                type: String,
                default: function () {
                    return require(`@/assets/images/logo/logo.png`)
                }
            },
            darkLogo: {
                type: String,
                default: function () {
                    return require(`@/assets/images/logo/logo-dark.png`)
                }
            },
            showName: {
                type: String,
                default: '',
            },
        }
    }
</script>