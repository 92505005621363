<template>
    <div id="hubspotForm" v-once></div>
</template>

<script>
    import { onMounted } from 'vue'

    export default {
        name: 'HubSpotForm',
        props: {
            formType: {
                type: String,
                default: 'contact',
            },
        },
        setup(props) {
            const formId = 'ticket' == props.formType ? 'e7502b2b-7f4c-4d74-857f-0cc96144f284' : 'fcfc235d-5dc9-47a7-a01f-2a1fbae1f3cb';
            onMounted(() => {
                const script = document.createElement('script');
                script.src = 'https://js.hsforms.net/forms/embed/v2.js';
                document.body.appendChild(script);
                script.addEventListener('load', () => {
                    if (window.hbspt) {
                        window.hbspt.forms.create({
                            cssRequired: '',
                            formId: formId,
                            portalId: '44724596',
                            region: 'na1',
                            target: '#hubspotForm',
                        })
                    }
                })
            })
        },
    }
</script>
