<template>
  <ul class="mainmenu">
    <li>
      <router-link to="/">
        Home
      </router-link>
    </li>
    <li>
      <router-link to="/contact">
        Contact
      </router-link>
    </li>
  </ul>
</template>

<script>
    export default {
        name: 'Nav'
    }
</script>
