<template>
  <div class="rbt-separator-mid">
    <div class="container">
      <hr class="rbt-separator m-0">
    </div>
  </div>
</template>

<script>
    export default {
        name: 'Separator'
    }
</script>