<template>
  <Layout>
    <!-- Start Slider Area -->
    <div class="slider-area slider-style-1 bg-transparent height-750 pt-0">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-7 col-xl-6 mt_md--40 mt_sm--40">
            <div class="inner text-left">
              <h1
                class="title theme-gradient display-one"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                Green Hollow
              </h1>
              <span
                class="subtitle"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                NAVIGATING GROWTH TOGETHER
              </span>
              <ul
                class="list-icon"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <li>
                  <Icon
                    name="check"
                    size="20"
                    icon-class="icon d-inline-flex align-items-center justify-content-center"
                  />
                  Deliver greater value to your customers faster
                </li>
                <li>
                  <Icon
                    name="check"
                    size="20"
                    icon-class="icon d-inline-flex align-items-center justify-content-center"
                  />
                  Maintain higher caliber leadership in your organization
                </li>
                <li>
                  <Icon
                    name="check"
                    size="20"
                    icon-class="icon d-inline-flex align-items-center justify-content-center"
                  />
                  Increase quality and productivity on your front lines
                </li>
              </ul>
              <div
                class="button-group mt--40"
                data-aos="fade-up"
                data-aos-delay="250"
              >
                <a
                  class="btn-default btn-medium round btn-icon"
                  href="https://tidycal.com/greenhollow/15min"
                  target="_blank"
                >
                  Book an intro call
                  <Icon
                    name="arrow-right"
                    size="20"
                    icon-class="icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->

    <Separator />

    <!-- Start Service Area -->
    <section class="rn-service-area rn-section-gap">
      <div class="container">
        <div class="row row--30">
          <div class="col-lg-12">
            <SectionTitle
              class="mb--50"
              text-align="center"
              subtitle="Where People and Technology Intersect"
              title="Serving the Needs of Our Clients"
              description='Our North Star is to<br /><span class="theme-gradient">increase the likelihood of success</span><br />of your current <span class="theme-gradient">improvement</span> efforts,<br />planned <span class="theme-gradient">growth</span>,<br />and ongoing <span class="theme-gradient">adaptation</span> to an ever changing market,<br />with <span class="theme-gradient">systematized operational solutions</span><br />and <span class="theme-gradient">iterative learning frameworks</span>.'
              data-aos="fade-up"
              data-aos-delay="60"
            />
          </div>
        </div>
        <div class="row row--15 service-wrapper">
          <div
            v-for="(service, index) in serviceCards"
            :key="index"
            class="col-lg-6 col-md-6 col-sm-6 col-12"
          >
            <ServiceCard
              :service="service"
              :service-style="`service__style--1 bg-color-blackest radius mt--25`"
              :icon-size="62"
              text-align="left"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- End Service Area -->

    <Separator />

    <!-- Start Timeline Area -->
    <div class="rwt-timeline-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="Our Solution Framework"
              title="Drive Lasting Outcomes"
              data-aos="fade-up"
              data-aos-delay="60"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 offset-lg-1 mt--50">
            <TimeLine
              :timeline-data="timelineData"
              :timeline-style="2"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- End Timeline Area -->

    <Separator />

    <!-- Start Elements Area -->
    <div class="rwt-team-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              subtitle="Our Experts"
              title="Meet Our Team"
              data-aos="fade-up"
              data-aos-delay="60"
            />
          </div>
        </div>
        <div class="row row--15">
          <div
            v-for="(teamMember, index) in teamCards"
            :key="index"
            class="col-lg-4 col-md-6 col-12 mt--30"
          >
            <TeamCard :team-member="teamMember" :team-style="teamStyle" />
          </div>
        </div>
      </div>
    </div>
    <!-- End Elements Area -->

  </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import Icon from '../components/icon/Icon'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import ServiceCard from '../components/elements/service/ServiceCard'
    import TeamCard from '../components/elements/teamCard/TeamCard'
    import TimeLine from '../components/elements/timeLine/TimeLine'

    export default {
        name: 'Company',
        components: {Icon, Layout, SectionTitle, Separator, ServiceCard, TeamCard, TimeLine},
        data() {
            return {
                serviceCards: [
                    {
                        icon: 'activity',
                        title: 'Software Product Companies',
                        description: '<ul><li>Experiencing productivity slowdowns</li><li>Struggling with results of rapid growth</li><li>Seeing increase in defects in production</li><li>Loss of alignment between teams</li><li>Not seeing much benefit from doing Agile</li></ul><p>We partner with product companies who are experiencing these challenges, or wanting to avoid these outcomes, as they progress through startup, growth, and maturity stages.</p>'
                    },
                    {
                        icon: 'map',
                        title: 'Technology Supported Companies',
                        description: '<ul><li>Poor communication with technology group</li><li>Technology not supporting impact on key metrics</li><li>Delays in technology requests limiting revenue</li><li>Multiple large transformation efforts and little result</li><li>Lack of trust in the technology business function</li></ul><p>When technology is not a core competency of any organization, that company will continue to struggle to reliably deliver value to its customers while adapting to a continuously evolving market.</p>'
                    },
                ],
                teamStyle: 1,
                teamCards: [
                    {
                        image: 'team-israel-carberry',
                        name: 'Israel J. Carberry',
                        designation: 'Lead Consultant',
                        location: 'Austin, Texas',
                        description: 'I grow people (typically software engineers) and guide change (usually in software orgs).',
                        socialNetwork: [
                            {
                                icon: 'linkedin',
                                url: 'https://www.linkedin.com/in/israelcarberry/'
                            },
                        ]
                    },
                    {
                        image: 'team-saul-allen',
                        name: 'Saul Allen',
                        designation: 'Head of Business Development',
                        location: 'Dallas, Texas',
                        description: '',
                        socialNetwork: [
                            {
                                icon: 'linkedin',
                                url: 'https://www.linkedin.com/in/sauljallen/'
                            },
                        ]
                    },
                    {
                        image: 'team-phineas-carberry',
                        name: 'Phineas Carberry',
                        designation: 'Game Design Consultant',
                        location: 'Dallas, Texas',
                        description: '',
                        socialNetwork: [
                            {
                                icon: 'linkedin',
                                url: 'https://www.linkedin.com/in/phineascarberry/'
                            },
                            {
                                icon: 'globe',
                                url: 'https://www.wolfstonetech.com/'
                            },
                        ]
                    },
                    {
                        image: 'team-gabrielle-gerstner',
                        name: 'Gabrielle Gerstner',
                        designation: 'Social Media Consultant',
                        location: 'Austin, Texas',
                        description: '',
                        socialNetwork: [
                            {
                                icon: 'linkedin',
                                url: 'https://www.linkedin.com/in/gabrielle-gerstner-b792b31bb/',
                            },
                        ],
                    },
                ],
                timelineData: [
                    {
                        description: 'We partner with your operational leadership to identify and address the key pain points or constraints for maximum business impact in an iterative and data driven learning process.',
                        title: 'Tactical Solution',
                    },
                    {
                        description: 'While partnering on the tactical solution, we also investigate the organizational dysfunction or management gap that allowed the issue to exist in the first place, and coach key staff for sustainable skill growth.',
                        title: 'Root Cause Resolution',
                    },
                    {
                        description: 'The implementation within the tactical solution and management development becomes a system that can be duplicated, becoming a new tool in your organizational skillset.',
                        title: 'Repeatable Framework',
                    },
                    {
                        description: 'Communication throughout the process reinforces the mindset for applying iterative learning and leadership growth to all key business functions, instilling a culture that identifies your company as a learning organization.',
                        title: 'Learning Organization',
                    },
                ],
             }
        }
    }
</script>
