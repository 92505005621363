import { createRouter, createWebHistory } from 'vue-router';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Company from '../views/Company'
import ContactUs from '../views/ContactUs'
import ErrorPage from '../views/404'

const routes = [
    {
        path: '/',
        name: 'Company',
        component: Company,
        meta: {
            title: 'Green Hollow :: Navigating Growth Together',
        },
    },
    {
        path: '/contact',
        name: 'ContactUs',
        component: ContactUs,
        meta: {
            title: 'Green Hollow :: Contact Us',
        },
    },
    {
        path: '/error',
        name: 'Error',
        component: ErrorPage,
        meta: {
            title: 'Green Hollow :: Huh?  Not found...',
        },
    }
]

const router = createRouter({
    base: process.env.BASE_URL,
    history: createWebHistory(),
    routes: routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    AOS.init();
    next();
    window.scrollTo(0, 0)
});

export default router;
