<template>
  <Layout>
    <!-- Start Contact Area  -->
    <div class="rwt-contact-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb--40">
            <SectionTitle
              text-align="center"
              subtitle="Need more information?"
              title="Contact Us!"
            />
          </div>
        </div>

        <div class="row row--15">
          <div class="col-lg-7">
            <HubSpotForm form-type="contact" />
          </div>
          <div class="col-lg-5 mt_md--30 mt_sm--30">
            <div class="rn-contact-address mt_dec--30">
              <div class="row">
                <div class="col-12">

                  <div class="rn-address">
                    <div class="icon">
                      <Icon
                        name="phone"
                        :size="40"
                      />
                    </div>
                    <div class="inner">
                      <h4 class="title">
                        Phone
                      </h4>
                      <p><a href="tel:+1 512-222-3041">+1 (512) 222 - 3041</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Contact Area  -->

  </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Icon from "../components/icon/Icon";
    import HubSpotForm from '../components/elements/hubspot/HubSpotForm'

    export default {
        name: 'ContactUs',
        components: {HubSpotForm, Icon, Layout, SectionTitle},
    }
</script>
