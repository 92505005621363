<template>
  <div class="copyright-area copyright-style-one variation-two">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 col-md-7 col-sm-12 col-12">
          <div class="copyright-left">
            <ul v-if="showPrivacyAndTerms" class="ft-menu link-hover">
              <li>
                <router-link to="#">
                  Privacy Policy
                </router-link>
              </li>
              <li>
                <router-link to="#">
                  Terms And Condition
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-5 mt_sm--20">
          <div class="copyright-center text-center">
            <ul v-if="showSocialLinks" class="social-icon social-default color-lessdark justify-content-center">
              <li>
                <a
                  href="https://www.linkedin.com/company/greenhollowtech/"
                  target="_blank"
                ><Icon name="linkedin" /></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
          <div class="copyright-right text-center text-lg-end">
            <p class="copyright-text">
              © {{ new Date().getFullYear() }} Green Hollow Technology LLC
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from '../../icon/Icon'

  export default {
    name: 'Copyright',
    components: {Icon},
    props: {
      showPrivacyAndTerms: {
        type: Boolean,
        default: false,
      },
      showSocialLinks: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
